<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="customerID">
                {{ sprintf($t('pages.developer.customerManagement.customer.save.edit'), [customer.full_name ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.developer.customerManagement.customer.save.new') }}</template>
        </h2>

        <div class="d-flex flex-wrap my-1">
            <router-link to="/developer/customer-management/customer" class="btn btn-primary align-self-center ms-4 back-page-btn">
                <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                </span>
                {{ $t("pages.developer.customerManagement.customer.title") }}
            </router-link>
        </div>
    </div>
    <el-form :model="form.data" ref="customerForm">
        <el-tabs v-model="tabModel" class="customer-tabs">
            <el-tab-pane :label="$t('pages.developer.customerManagement.customer.save.tabs.general')" name="general">
                <div class="row g- g-xl-9">
                    <div class="col-lg-5 col-xl-3 mt-7 mt-md-0" v-if="form.updateStatus">
                        <div class="card mb-5 mb-xl-8">
                            <div class="card-body pt-15">
                                <div class="d-flex flex-center flex-column mb-5">
                                    <div class="symbol symbol-150px mb-7">
                                        <img src="/media/svg/files/blank-image.svg" alt="image"/>
                                    </div>

                                    <span class="fs-3 text-gray-800 fw-bolder mb-1 text-center">
                                       {{ customer.full_name }}
                                    </span>

                                    <span class="fs-5 fw-bold text-muted mb-6">
                                        <a :href="'mailto:' + customer.email" class="text-gray-600 text-hover-primary mb-1">
                                            {{ customer.email }}
                                        </a>
                                    </span>

                                    <el-popconfirm v-if="customer.id && customer.is_deletable" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord">
                                        <template #reference>
                                            <a class="btn btn-danger">
                                                <i class="bi bi-trash fs-4 me-2"></i>
                                                {{ $t('common.remove') }}
                                            </a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                                <div class="separator separator-dashed my-3"></div>
                                <div class="text-center fs-4 py-3">
                                    <span class="fs-5 text-gray-800 fw-muted mb-1 mt-3">
                                        {{ $moment(customer.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-6 mb-lg-0">
                            <div class="card-header">
                                <h2 class="card-title fw-bolder">{{ $t('pages.developer.customerManagement.customer.save.action.title') }}</h2>
                            </div>
                            <div class="card-body">
                                <div class="d-flex flex-stack pb-5 fs-6 mt-5" v-for="(action, actionIndex) in ['is_deletable', 'is_editable']" :key="actionIndex">
                                    <div class="fw-bolder">
                                        {{ $t('pages.developer.customerManagement.customer.save.action.type.' + action) }}
                                    </div>
                                    <div class="text-gray-600">
                                        <span class="badge" :class="customer[action] ? 'badge-success' : 'badge-danger'">{{ $t('common.' + ((customer[action]) ? 'yes' : 'no')) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="form.updateStatus ? 'col-lg-7 col-xl-9' : 'col-md-12'">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="row">
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.cols.name') }}</label>
                                        <el-form-item prop="firstname" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.firstname" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.cols.surname') }}</label>
                                        <el-form-item prop="lastname" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.lastname" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.cols.email') }}</label>
                                        <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])">
                                            <el-input v-model="form.data.email" type="text"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1" v-if="!form.updateStatus">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.save.cols.password') }}</label>
                                        <el-form-item prop="password" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.password" show-password/>
                                        </el-form-item>
                                    </div>
                                    <div class="fv-row mb-1" :class="form.updateStatus ? 'col-md-6' : 'col-md-3'">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.cols.group') }}</label>
                                        <el-form-item prop="group_id" :rules="$validation.getMessage(['required'])">
                                            <el-select v-model="form.data.group_id" :placeholder="$t('common.chooseSelect')" class="w-100" filterable>
                                                <el-option v-for="(group, groupIndex) in groups" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="fv-row mb-1" :class="form.updateStatus ? 'col-md-6' : 'col-md-3'">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.save.cols.company') }}</label>
                                        <el-form-item prop="company_id" :rules="$validation.getMessage(['required'])">
                                            <el-select v-model="form.data.company_id" :placeholder="$t('common.chooseSelect')" class="w-100" filterable>
                                                <el-option v-for="(company, companyIndex) in companies" :key="companyIndex" :value="company.id" :label="company.name"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                        <el-form-item prop="active" class="active-form-item">
                                            <el-radio-group v-model="form.data.active">
                                                <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                                <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-7 pt-4 mb-6 mb-xl-9" v-if="form.updateStatus">
                            <div class="card-body pt-5 pb-5">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.save.cols.password') }}</label>
                                    <el-form-item prop="password">
                                        <el-input v-model="form.data.password" type="text"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('pages.developer.customerManagement.customer.save.tabs.storage')" name="storage">
                <div class="d-flex justify-content-end">
                    <a class="btn btn-sm btn-primary" @click="newStorage">
                         <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr009.svg"/>
                        </span>
                        {{ $t('pages.developer.customerManagement.customer.save.storage.new') }}
                    </a>
                </div>
                <div class="card pt-4 mt-5 mb-6 mb-xl-9" v-if="form.data.storages.length">
                    <div class="card-body pt-5 pb-5">
                        <div v-for="(storage, storageIndex) in form.data.storages" :key="storageIndex">
                            <h4>{{ storage.name }}</h4>
                            <div class="row mb-6">
                                <label class="col-lg-3 col-form-label required fs-6 fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.cols.size') }}</label>
                                <el-form-item :prop="'storages['+storageIndex+'].size'" :rules="$validation.getMessage(['required'])" class="col-lg-9 fv-row d-flex">
                                    <el-input v-model="form.data.storages[storageIndex].size" v-mask="{ alias: 'integer', allowMinus:false, placeholder: '', rightAlign: false}" class="input-with-select">
                                        <template #append>
                                            <el-form-item :prop="'storages['+storageIndex+'].unit'" :rules="$validation.getMessage(['required'])">
                                                <el-select v-model="form.data.storages[storageIndex].unit" :placeholder="$t('common.chooseSelect')" style="width: 150px">
                                                    <el-option v-for="(unit, unitIndex) in sizeUnits" :key="unitIndex" :value="unit" :label="unit">{{ unit }}</el-option>
                                                </el-select>
                                            </el-form-item>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="row justify-content-center px-9 mb-4">
                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ storage.size_detail.size }}</span>
                                        <span>{{ storage.size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.total') }}</span>
                                </div>
                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ storage.used_size_detail.size }}</span>
                                        <span>{{ storage.used_size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.used') }}</span>
                                </div>
                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ storage.free_size_detail.size }}</span>
                                        <span>{{ storage.free_size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.empty') }}</span>
                                </div>

                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ storage.usage_percentage }}</span>
                                        <span>%</span>
                                    </div>
                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.fullRate') }}</span>
                                </div>
                                <div class="separator separator-dashed my-10"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="d-flex justify-content-end" v-if="!(tabModel == 'storage' && !form.data.storages.length) && (customer.is_editable == undefined || customer.is_editable)">
            <div>
                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                    <span v-if="form.loading" class="indicator-progress">
                    {{ $t("messages.wait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                </button>
            </div>
        </div>
    </el-form>
    <div class="modal fade" id="kt_modal_storage" ref="storageModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ $t('pages.developer.customerManagement.customer.save.storage.new') }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitStorage()" :model="form.storageData" ref="storageForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.customerManagement.customer.save.storage.cols.disk') }}</label>
                                <el-form-item prop="id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.storageData.id" :placeholder="$t('common.chooseSelect')" class="w-100" filterable @change="changedStorage">
                                        <el-option v-for="(storage, storageIndex) in filteredStorages" :key="storageIndex" :value="storage.id" :label="storage.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="row justify-content-center px-9 mb-4" v-if="Object.keys(selectedStorage).length">
                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ selectedStorage.size_detail.size }}</span>
                                        <span>{{ selectedStorage.size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.total') }}</span>
                                </div>

                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ selectedStorage.used_size_detail.size }}</span>
                                        <span>{{ selectedStorage.used_size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.used') }}</span>
                                </div>

                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ selectedStorage.free_size_detail.size }}</span>
                                        <span>{{ selectedStorage.free_size_detail.unit }}</span>
                                    </div>

                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.empty') }}</span>
                                </div>


                                <div class="col-6 col-md-3 text-center mb-2 mb-xs-0">
                                    <div class="text-gray-800 fw-bold fs-3">
                                        <span class="m-0 me-1">{{ selectedStorage.usage_percentage }}</span>
                                        <span>%</span>
                                    </div>
                                    <span class="text-gray-500 fs-8 d-block fw-bold">{{ $t('pages.developer.customerManagement.customer.save.storage.field.fullRate') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-center">
                        <button class="btn btn-lg btn-primary" type="submit">
                            <span>{{ $t("btn.save") }}</span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "_id",
    components: {
        CustomTable
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                data: {
                    storages: [],
                    active: true
                },
                storageData: {},
            },
            selectedStorage: {},
            customer: {},
            sizeUnits: ['GB', 'TB', 'PB']
        }
    },
    computed: {
        customerID() {
            return this.$route.params.id;
        },
        groups() {
            return this.$store.state.developer.customer.group.table.data;
        },
        companies() {
            return this.$store.state.developer.customer.company.table.data;
        },
        storages() {
            return this.$store.state.developer.storage.table.data;
        },
        filteredStorages() {
            let addedStorages = this.form.data.storages.map(storage => {
                return storage.id;
            })

            let storages = this.storages.filter((storage) => !addedStorages.includes(storage.id));
            return storages;
        }
    },
    created() {
        if (this.customerID && !(this.customerID > 0)) {
            this.$router.push({
                path: "/developer/customer-management/customer"
            });
        }
    },
    mounted() {
        if (this.customerID && this.customerID > 0) {
            this.loadCustomer();
        }

        this.$store.dispatch('developer/customer/company/get', {
            page: {pageSize: 9999}
        });
        this.$store.dispatch('developer/customer/group/get', {
            page: {pageSize: 9999}
        });
        this.$store.dispatch('developer/storage/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        loadCustomer(customerID = this.customerID) {
            this.axios.get(this.endpoint.get('customer', 'developer') + '/' + customerID).then((response) => {
                let data = response.data.data;
                this.customer = this.cloneData(data);

                data.storages = data.storages.map((storage) => {
                    if(storage.pivot.quota_detail){
                        storage.size = storage.pivot.quota_detail.software.size;
                        storage.unit = storage.pivot.quota_detail.software.unit;
                    }
                    return storage
                });

                this.form.data = data;

                this.form.updateStatus = true;
            })
        },
        onSubmit() {
            this.$refs.customerForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.cloneData(this.form.data);

                    if (this.form.data.id) {
                        this.axios.put(this.endpoint.get('customer', 'developer') + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadCustomer();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoint.get('customer', 'developer'), formData).then(response => {
                            this.onResponse(response.data, () => {
                                let customerID = response.data.data.id;
                                this.$router.push({
                                    path: "/developer/customer-management/customer/save/" + customerID
                                });
                                this.loadCustomer(customerID);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    this.$notify({
                        type: 'warning',
                        title: this.$t("messages.warning"),
                        message: this.$t("pages.developer.customerManagement.customer.save.warnings.validate"),
                    })

                    return false;
                }
            });
        },
        newStorage() {
            this.form.storageData = {
                size: 0
            };
            this.selectedStorage = {};
            this.showModal(this.$refs.storageModal);
        },
        onSubmitStorage() {
            this.$refs.storageForm.validate((valid) => {
                if (valid) {
                    let formData = Object.assign(this.selectedStorage,this.form.storageData);
                    this.form.data.storages.push(formData);

                    this.hideModal(this.$refs.storageModal);
                } else {
                    return false;
                }
            });
        },
        changedStorage() {
            this.selectedStorage = {};

            if (this.form.storageData.id) {
                let found = this.storages.filter((storage) => storage.id == this.form.storageData.id);

                if (found.length == 1) {
                    this.selectedStorage = found[0];
                }
            }
        },
        deleteRecord(){
            this.$store.dispatch("developer/customer/delete", {
                id: [this.customer.id]
            }).then((successDeleted) => {
                if(successDeleted) {
                    setTimeout(() => {
                        this.$router.push({
                            path: "/developer/customer-management/customer"
                        });
                    }, 500)
                }
            });
        },
    }
}
</script>

<style>
.active-form-item .el-form-item__content {
    line-height: unset;
}
.input-with-select .el-input-group__append .el-form-item__content {
    line-height: 38px !important;
}
.input-with-select .el-input-group__append .el-select .el-input__inner{
    height: 38px !important;
    line-height: 38px !important;
}
</style>